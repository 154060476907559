import { useRouter } from 'next/navigation'
import { useMemo } from 'react'

import { configs } from '@/constants'
import { banks } from '@/constants/data'
import { useSelector } from '@/store'
import { cookie } from '@/utils/storage'

export function useAuth() {
  // __STATE's
  const router = useRouter()
  const user = useSelector(({ user }) => user)

  // __RETURN
  return useMemo(() => {
    const bank = banks.find((bank) => bank.key.match(user?.Bank || 'กสิกรไทย'))

    return {
      ...user,
      BankIcon: bank?.image,
      isAuth: () => {
        const accessToken = cookie.get(configs.APP_AUTH_ACCESS)
        return Boolean(accessToken)
      }
    }
  }, [router, user])
}
