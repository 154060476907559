export const bankReceiver = {
  name: 'ธนาคารไทยพาณิชย์',
  accountName: 'วีระศักดิ์ พลขันธ์',
  accountNumber: '4231846265'
}

export const banks = [
  { name: 'ไทยพาณิชย์ (SCB)', key: 'ธ.ไทยพาณิชย์', image: 'scb.svg' },
  { name: 'กสิกรไทย (KBANK)', key: 'ธ.กสิกรไทย', image: 'kbank.svg' },
  { name: 'กรุงไทย (KTB)', key: 'ธ.กรุงไทย', image: 'krungthai.svg' },
  { name: 'ทหารไทยธนชาต (TTB)', key: 'ธ.ทหารไทยธนชาต', image: 'ttb.svg' },
  // { name: 'ทรูมันนี่วอลเล็ท', key: 'ทรูมันนี่วอลเล็ท', image: 'truewallet.svg' },
  { name: 'กรุงเทพ (BBL)', key: 'ธ.กรุงเทพ', image: 'bangkok.svg' },
  { name: 'ยูโอบี (UOB)', key: 'ธ.ยูโอบี', image: 'uob.svg' },
  { name: 'กรุงศรีอยุธยา (BAY)', key: 'ธ.กรุงศรีอยุธยา', image: 'krungsri.svg' },
  { name: 'ออมสิน (GSB)', key: 'ธ.ออมสิน', image: 'aomsin.svg' },
  { name: 'ธกส. (BAAC)', key: 'ธ.ธกส', image: 'baac.svg' },
  { name: 'ซีไอเอ็มบีไทย (CIMB)', key: 'ธ.ซีไอเอ็มบีไทย', image: 'cimb.svg' },
  { name: 'ทิสโก้ (TISCO)', key: 'ธ.ทิสโก้', image: 'tisco.svg' },
  { name: 'เกียรตินาคิน (KKP)', key: 'ธ.เกียรตินาคิน', image: 'kiatnakin.svg' },
  { name: 'แลนด์แอนด์เฮ้าส์ (LHFG)', key: 'ธ.แลนด์แอนด์เฮ้าส์', image: 'lh.svg' }
]
