import { useCallback, useMemo } from 'react'

import { useSelector } from '@/store'

export function useTranslate() {
  // __STATE's
  const translate = useSelector(({ app }) => app.translate)

  // __FUNCTION's
  const getValue = useCallback(
    (keyPath: string) => {
      // Split the keyPath into an array of keys
      const keys = keyPath.split('.')

      // Reduce the keys to find the nested value
      return keys.reduce((accumulator, currentKey) => {
        if (accumulator && currentKey in accumulator) {
          return accumulator[currentKey]
        }

        return undefined
      }, translate)
    },
    [translate]
  )

  // __RETURN
  return useMemo(() => {
    return {
      t: getValue
    }
  }, [getValue])
}
