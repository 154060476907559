'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { type ReactNode, useMemo } from 'react'
import { Provider } from 'react-redux'

import ReduxStore from '@/store'

import Bootstrap from './addon/bootstrap'
import { DialogObserver } from './addon/dialog'
import { LoaderObserver } from './addon/loader'
import { NoticeObserver } from './addon/notice'

type ProviderProps = { children: ReactNode }

export function AppProvider({ children }: ProviderProps) {
  // __STATE's
  const queryClient = useMemo(() => new QueryClient(), [])

  // __RENDER
  return (
    <Provider store={ReduxStore}>
      <Bootstrap />
      <LoaderObserver />

      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>

      <DialogObserver />
      <NoticeObserver />
    </Provider>
  )
}
